.custom__btn {
	background-color: var(--main-color) !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border: 0 !important;
	border-radius: 50px !important;
	padding: 1px 12px !important;
	line-height: 35px !important;
	min-width: 100px !important;
	outline: none;
}

.btn__icon__container {
	color: #000 !important;
	background: #fff;
	border-radius: 58px;
	height: 27px;
	width: 27px;
	line-height: 29px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn__icon__container > .MuiSvgIcon-root {
	font-size: 16px;
}
.custom__btn__text {
	font-size: 14px;
	text-transform: none;
	text-align: center;
	width: 100%;
	margin-right: 5px;
	margin-left: 5px;
}

.imges__gallery {
	width: 90%;
	height: auto;
}

.image__gallery .image__gallery__slide img {
	width: 100%;
	height: 100%;
	max-height: 50vh !important;
	object-position: cover;
	overflow: hidden;
	object-position: center center;
}

.fullscreen .image-gallery-slide img {
	width: 100vw;
	height: 100vh;
	max-height: 100vh !important;
	object-fit: contain;
	overflow: hidden;
	object-position: center center;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
	height: 90px !important;
}

/* Tabs */

.customTags .MuiTabs-scroller {
	overflow-x: scroll !important;
}

.customTabs .customTabs__item {
	padding: 0px !important;
	min-width: 0px !important;
	margin-right: 20px !important;
	text-transform: inherit !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	outline: none !important;
}

.customTabs .active {
	color: var(--main-color) !important;
}

/* Cards */
.customCard {
	height: 100%;
	max-width: 350px;
	box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
	overflow: hidden;
	outline-width: 0px !important;
}

.customCard .customCard__image {
	height: 200px;
}

.customCard .customCard__title {
	font-weight: 500;
}

.customCard .customCard__caption {
	color: darkslategray;
	font-weight: 400;
}

/* Dialog */
.projectDialog {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 700px;
	height: auto;
	margin: 0 auto;
}

/* .projectDialog::-webkit-scrollbar {
	display: none;
} */

.projectDialog .projectDialog__image {
	max-height: 270px;
	width: 100%;
	object-fit: cover;
	object-position: center center;
}

.projectDialog .projectDialog__description {
	margin-top: 10px !important;
}

.projectDialog__description p {
	margin-bottom: -3px;
	font-size: 14px;
	margin: 0;
}

.projectDialog__description span {
	font-weight: bold;
}

.projectDialog__description ul {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

.projectDialog .projectDialog__actions {
	justify-content: center !important;
	margin-bottom: 10px;
}

.projectDialog .projectDialog__icon {
	color: darkslategray;
	margin: 0px 12px;
	cursor: pointer;
}

.projectDialog .projectDialog__icon :hover {
	color: var(--main-color);
}

::-webkit-scrollbar {
	display: none;
}

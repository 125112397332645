/* .grid__container {
	border: 1px solid red;
} */

:root {
	--main-color: #ffc500;
}

.top__60 {
	margin-top: 60px !important;
}

.top__30 {
	margin-top: 30px !important;
}

.mb__30 {
	margin-bottom: 30px !important;
}
.p__30 {
	padding: 30px !important;
}

.pb__45 {
	padding-bottom: 45px !important;
}
.pt__45 {
	padding-top: 45px !important;
}

.p__50 {
	padding: 50px !important;
}
.graybg {
	background-color: #fafafa;
}
@media (max-width: 991px) {
	.top__60 {
		margin-top: 30px !important;
	}
}

.container__shadow {
	box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
	-webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
	-moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}

.main__content {
	background-color: white;
	min-height: 100vh;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	margin-bottom: 30px;
	overflow: hidden;
}
